.single-work-process-inner {
  position: relative;
  min-height: 320px;
  border-radius: 20px;
  box-shadow: $box-shadow;
  margin-bottom: 30px;
  padding: 30px 20px;
  transition: 0.4s;
  .thumb {
    img {
      width: 57px;
      transition: 0.4s;
    }
  }
  .details {
    .process-count {
      color: var(--main-color);
      font-weight: 600;
    }
    h5 {
      font-weight: 700;
    }
    .content {
      margin-bottom: 0;
    }
  }
  &:hover {
    // box-shadow: $box-shadow2;
    box-shadow: 0px 10px 30px rgb(0 33 71 / 27%);
    transform: scale(1.01);
    .thumb {
      img {
        transform: rotateY(180deg);
      }
    }
  }
}
.process-arows {
  width: 65%;
}
.single-work-process-inner-2 {
  padding: 25px;
  margin-top: -90px;
  .thumb {
    height: 120px;
    width: 120px;
    line-height: 120px;
    text-align: center;
    border-radius: 5px;
    box-shadow: $box-shadow;
    display: inline-block;
    position: relative;
    background: #fff;
    img {
      transition: 0.4s;
    }
    .process-count {
      height: 30px;
      width: 30px;
      color: #fff;
      background: var(--main-color);
      border-radius: 50%;
      line-height: 030px;
      text-align: center;
      font-size: 14px;
      position: absolute;
      right: -15px;
      top: 45px;
      transition: 0.4s;
    }
  }
  .details {
    p {
      margin-bottom: 0;
    }
  }
  &:hover {
    .thumb {
      img {
        transform: rotateY(180deg);
      }
      .process-count {
        background: var(--heading-color);
      }
    }
  }
}

.single-work-process-inner-3 {
  padding: 40px;
  padding-top: 0;
  img {
    border-radius: 50%;
  }
}

.line-img {
  width: 40%;
}
.white-transparent {
  background-color: rgb(255 255 255 / 50%);
}
.tw-width {
  width: 20%;
}
// .time-line {
//   position: relative;
//   display: flex;
//   z-index: 1;
//   left: 0;
//   top: 0;
//   background-color: #fbb04c;
//   height: 2px;
//   width: 2160px;
// }
// .circle {
//   position: absolute;
//   width: 15px;
//   background-color: #fbb04c;
//   height: 15px;
//   top: -25px;
//   right: 112px;
//   border-radius: 100%;
// }
// .line-block {
//   margin-top: 16px;
//   box-shadow: unset;
// }
@media screen and (max-width: 999px) {
  .tw-width {
    width: inherit;
  }

  // .time-line {
  //   display: none;
  // }
  // .circle {
  //   display: none;
  // }
  // .line-block {
  //   margin-top: 16px;
  //   box-shadow: 0px 3px 20px rgba(0, 33, 71, 0.06);
  // }
}
