/*----------------------------------------------
    # banner style
----------------------------------------------*/
.banner-area {
  margin-top: -120px;
}
.banner-area-1 {
  padding-top: 180px;
  .animate-img-1 {
    position: absolute;
    left: 80px;
    bottom: 100px;
  }
  .animate-img-2 {
    position: absolute;
    left: 100px;
    bottom: 50px;
  }
}
.banner-area-2 {
  padding-top: 210px;
  padding-bottom: 70px;
  .bg-img-2 {
    position: absolute;
    right: -100px;
    top: 0;
    width: 660px;
  }
  .video-play-btn-hover {
    cursor: pointer;
  }
}
.banner-area-3 {
  margin-top: 0;
  .bg-gray {
    padding: 140px 0;
    position: relative;
  }
  .img-right {
    position: absolute;
    right: 0;
    top: 0;
    width: 55%;
    bottom: 0;
    height: 100%;
    z-index: 1;
  }
  &:after {
    content: "";
    position: absolute;
    right: 40%;
    top: 0;
    width: 20%;
    height: 100%;
    background-image: linear-gradient(to right, #f8f9fc 50%, transparent);
    z-index: 2;
  }
}

.navbar-area-2 {
  .nav-right-part {
    align-items: center;
    .navbar-phone {
      color: var(--heading-color);
      background: transparent;
      .icon {
        background: #246bfd2e;
        img {
          filter: invert(38%) sepia(100%) saturate(3579%) hue-rotate(212deg)
            brightness(97%) contrast(105%);
        }
      }
      h5,
      span {
        color: var(--heading-color);
      }
    }
  }
}

.banner-inner {
  position: relative;
  z-index: 3;
  .subtitle {
    border-radius: 30px;
    box-shadow: 0 4.4px 30px rgba(19, 16, 34, 0.05);
    background: #fff;
    padding: 8px 20px;
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 15px;
    letter-spacing: 1px;
  }
  .title {
    font-size: 60px;
    margin-bottom: 20px;
    font-weight: 700;
    span {
      color: var(--main-color);
    }
  }
  .content {
    margin-bottom: 32px;
  }
}

.banner-thumb {
  position: relative;
  .main-img {
    border-radius: 300px 300px 0 0;
  }
  &:after {
    content: "";
    position: absolute;
    left: -25px;
    bottom: -25px;
    width: 100%;
    height: 100%;
    border: 5px solid var(--main-color);
    border-radius: 300px 300px 0 0;
  }
  .banner-animate-img {
    position: absolute;
  }
  .banner-animate-img-1 {
    left: -140px;
  }
  .banner-animate-img-2 {
    right: 25px;
    bottom: -25px;
    height: 120px;
    width: 120px;
    background: var(--main-color);
    padding: 20px;
  }
  .banner-animate-img-3 {
    right: -9px;
    bottom: -68px;
  }
}

.banner-thumb-2 {
  position: relative;
  .banner-animate-img {
    position: absolute;
  }
  .main-img-wrap {
    position: relative;
    .banner-animate-img-1 {
      left: -140px;
      width: 120px;
      z-index: 1;
    }
    .banner-animate-img-2 {
      right: -70px;
      z-index: 1;
      top: 50px;
    }
    .banner-animate-img-3 {
      left: -200px;
      bottom: 100px;
      z-index: 1;
    }
  }
  .banner-animate-img-4 {
    right: -20px;
    top: 0;
    z-index: 0;
  }
  .main-img-wrap {
    z-index: 1;
    margin-left: 160px;
  }
}

.banner-thumb-3 {
  position: relative;
  .banner-animate-img {
    position: absolute;
  }
  position: relative;
  .banner-animate-img-1 {
    left: -50px;
    top: 30%;
  }
  .banner-animate-img-2 {
    right: 50px;
    bottom: 40px;
  }
}

/* RotatingImages.module.scss */

.banner-logo-container {
  display: flex;
  position: relative;
  right: -50px;
  width: 200px;
  height: 60vh;
  perspective: 1000px;

  .rotating-logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    animation: startAnimation 3s linear forwards; /* Start animation after 2 seconds */

    .left-chat {
      // width: 100%;
      // height: 100%;
      position: relative;
      left: 44px;
      transform: rotateY(360deg); /* Initial rotation */
      transform-style: preserve-3d;
      animation: rotate-left 10s linear infinite;
    }

    .right-chat {
      // width: 100%;
      // height: 100%;
      transform-style: preserve-3d;
      animation: rotate-right 10s linear infinite;
    }
  }
}

.scroll-wheel {
  position: absolute;
  left: 49%;
  max-width: 60px;
  bottom: 20px;
}
@keyframes rotate-left {
  0% {
    transform: rotateY(0deg);
  }
  100% {
    transform: rotateY(360deg);
  }
}

@keyframes rotate-right {
  0% {
    transform: rotateY(360deg);
  }
  100% {
    transform: rotateY(0deg);
  }
}

/* Keyframes for the delay animation */
@keyframes startAnimation {
  0% {
    opacity: 0; /* Initially hidden */
  }
  100% {
    opacity: 1; /* Fully visible after 2 seconds */
  }
}
